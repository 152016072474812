import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="alerts"
export default class extends Controller {
  static targets = ["dropdown"];
  connect() {
    console.log("helllo from alerts");
    console.log(this.successPopupTarget);
  }
  resetAutomaticAlerts(e) {
    console.log("resetting automatic alerts");
  }

  toggleDropdown(e) {
    e.preventDefault();
    const dropdownId = e.currentTarget.dataset.alertId;
    const dropdown = document.getElementById(dropdownId);
    dropdown.classList.toggle("show");

    const closeDropdown = (event) => {
      if (event.target.dataset.alertId !== dropdownId) {
        dropdown.classList.remove("show");
      }
    };

    if (!dropdown.classList.contains("show")) {
      document.removeEventListener("click", closeDropdown);
    } else {
      document.addEventListener("click", closeDropdown);
    }
  }
  toggleEditModal(e) {
    const modalId = e.target.dataset.modalId;
    const modal = document.getElementById(modalId);
    const closeModal = (event) => {
      modal.classList.remove("show");
      modal.style.display = "none";
    };
    const openModal = (event) => {
      modal.classList.add("show");
      modal.style.display = "block";
    };
    if (!modal.classList.contains("show")) {
      document.addEventListener("click", openModal);
    } else {
      document.addEventListener("click", closeModal);
    }

    // document.addEventListener("click", (event) => {
    //   if (modal.classList.contains("show") && modal.contains(event.target)) {
    //     return;
    //   } else {
    //     closeModal();
    //   }
    // });
    // e.preventDefault();
    // const modalId = e.target.dataset.alertId;
    // const modal = document.getElementById(modalId);
    // modal.classList.toggle("show");
  }

  showSuccessPopup(msg) {
    const successPopup = document.getElementById("success-popup");
    const successPopupMsg = document.getElementById("successPopupMsg");
    successPopup.classList.add("show");
    successPopup.style.display = "block";
    successPopupMsg.innerText = msg;

    setTimeout(() => {
      successPopup.style.display = "none";
      successPopup.classList.remove("show");
      window.location.reload();
    }, 1500);
  }

  showErrorPopup(msg) {
    const errorPopup = document.getElementById("error-popup");
    const errorPopupMsg = document.getElementById("errorPopupMsg");

    errorPopup.classList.add("show");
    errorPopup.style.display = "block";
    errorPopupMsg.innerText = msg;
    setTimeout(() => {
      errorPopup.style.display = "none";
      errorPopup.classList.remove("show");
      window.location.reload();
    }, 1500);
  }

  toggleActiveManual(e) {
    fetch("/activate_manual", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log(data);
          this.showSuccessPopup(data.message);
        } else {
          console.log(data);
          this.showErrorPopup(data.error);
        }
      })
      .catch(function () {
        console.log("There was an error activating the notification.");
        showErrorPopup();
      });
  }

  toggleActiveAutomatic(e) {
    fetch("/activate_automatic", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.showSuccessPopup(data.message);
        } else {
          this.showErrorPopup(data.error);
        }
      })
      .catch((e) => {
        console.log(e);
        this.showErrorPopup();
      });
  }
}

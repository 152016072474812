import { Controller } from "@hotwired/stimulus";
import JSConfetti from "js-confetti";

// Connects to data-controller="hello"
export default class extends Controller {
  connect() {
    const flashMessage = document.querySelector(".flash-message");
    console.log(flashMessage);
    if (
      flashMessage &&
      flashMessage.dataset.type === "notice" &&
      flashMessage.dataset.message === "signed_in"
    ) {
      this.openWelcomeModal();
    }
  }
  closeWelcomeModal() {
    const welcomeModal = document.querySelector(".welcome-modal");
    welcomeModal.classList.remove("show", "fade");
    document.querySelector("body").style.overflow = "auto";
  }

  openWelcomeModal() {
    const jsConfetti = new JSConfetti();
    const welcomeModal = document.querySelector(".welcome-modal");
    welcomeModal.classList.add("show", "fade");
    jsConfetti.addConfetti();
    document.querySelector("body").style.overflow = "hidden";
  }
}
